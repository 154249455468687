import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { DeliveryTypeInterface } from "@delivery-type/ports/interfaces";

export abstract class DeliveryTypeAbstraction {
  abstract list(query: string): Observable<RespListInterface<DeliveryTypeInterface>>;
  abstract create(deliveryType: DeliveryTypeInterface): Observable<RespInterface<DeliveryTypeInterface>>;
  abstract update(deliveryType: DeliveryTypeInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<DeliveryTypeInterface>>;
  abstract delete(id: string): Observable<RespInterface<DeliveryTypeInterface>>;
}
